<template>
    <div class="w-full h-full mt-3">
        <NavBar />
        <div class="lg:hidden fixed top-0 left-0 w-full h-full z-30 bg-black opacity-50 transition-all translate ease-in-out"
            :class="showSidebar ? '' : 'hidden'" @click="showSidebar = false">
        </div>
        <Sidebar :is-open="showSidebar" class="w-64 transition-transform" />
        <div class="bg-gray-50 p-4 pt-14 h-full" :class="showSidebar ? 'lg:ms-64 ease-out' : 'ease-in'">
            <!-- <Breadcrumb class="mb-2" /> -->
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
const showSidebar = useShowSidebar();
// const showRightSidebar = useShowListingsSidebar();
</script>